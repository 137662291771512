html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  background-color: #eee;
}

body {
  line-height: 1;
  overflow: hidden;
  overscroll-behavior: contain;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a,
a:link,
a:active,
a:visited,
a:focus,
input:focus,
input:active,
button:focus {
  display: block;
  text-decoration: none;
  color: inherit;
  border: inherit;
  outline: 0;
  border: 0;
}

a,
button,
a:hover,
button:hover,
a i,
button i,
a span,
button span,
a:hover i,
button:hover i {
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none
}

::placeholder {
  opacity: 0.3;
  color: #444;
}

::-webkit-input-placeholder {
  opacity: 0.3;
  color: #444;
}

::-moz-placeholder {
  opacity: 0.3;
  color: #444;
}

:-ms-input-placeholder {
  opacity: 0.3;
  color: #444;
}

:-moz-placeholder {
  opacity: 0.3;
  color: #444;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.modal {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loading-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
  margin-left: -40px;
  margin-top: -40px;
}

.icon-loading {
  border: 16px solid #24b47e;
  border-top: 16px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  color: #24b47e;
  font-size: 12px;
  font-weight: 900;
  line-height: 60px;
  text-align: center;
}

.icon-gz {
  background: url(./images/icon-gz.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-tu {
  background: url(./images/icon-tu.png) no-repeat;
  background-size: 85% auto;
  background-position: center center;
  background-color: #fff !important;
}

.icon-sg {
  background: url(./images/icon-sg.logo.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
  background-color: #fff !important;
}

.icon-xg {
  background: url(./images/icon-xg.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-ct {
  background: url(./images/icon-ct.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
  background-color: #444 !important;
}

.icon-qe {
  background: url(./images/icon-qe.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
  background-color: #383838 !important;
}

.icon-ok {
  background: url(./images/icon-udr.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-gt {
  background: url(./images/icon-gt.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-bv {
  background: url(./images/icon-bv.jpg) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-gdr {
  background: url(./images/icon-gdr.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-rx {
  background: url(./images/icon-rx.png) no-repeat;
  background-size: 50% auto;
  background-position: center center;
}

.icon-tpz {
  background: url(./images/icon-tpz.png) no-repeat;
  background-size: 80% auto;
  background-position: center center;
}

.icon-ts {
  background: url(./images/icon-ts.png) no-repeat;
  background-size: 67% auto;
  background-position: center center;
}

.search-info.visual .div-info.icon-system.icon-od {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

header {
  position: relative;
  display: block;
  height: auto;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 99;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
}

header h1 {
  display: block;
  padding: 0;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
}

header ul {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99;
}

header ul li {
  display: inline-block;
  margin-right: 0px;
  vertical-align: top;
  text-align: center;
}

header ul li a,
header ul li .btn {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  color: #444;
}

header ul li+li {
  margin-left: 10px;
}

.btn.signout {
  display: block;
  height: 33px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #1CB06D;
  font-size: 15px;
  line-height: 15px;
  /* border: 0; */
  border-radius: 10px;
  font-weight: 700;
  color: #1CB06D;
  box-sizing: border-box;
}

.btn.phone {
  display: block;
  height: 33px;
  padding: 0 15px;
  background-color: #8ec28a;
  font-size: 12px;
  line-height: 15px;
  border: 0;
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.btn.kakao {
  display: block;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0px;
  background-color: #FFCD00;
  font-size: 12px;
  border: 1px solid #FFCD00;
  border-radius: 10px;
  font-weight: 700;
  color: #1E1E1E;
  box-sizing: border-box;
}

/* .btn.kakao:before {
  display: inline-block;
  content: '';
  width:22px;
  height:20px;
  margin-right:5px;
  background-image: url(https://d38v45mghny9e4.cloudfront.net/common/pcrtn/ico_newfriends_v1_181018_v2.png);
  background-size: 300px 390px;
  background-repeat: no-repeat;
  background-position: -38px 0;
} */
.btn.kakao span {
  display: inline-block;
  line-height: 20px;
  vertical-align: top;
}

.btn.naver {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.btn.naver:before {
  display: inline-flex;
  content: '';
  width: 22px;
  height: 22px;
  margin-right: 5px;
  background-image: url(./images/icon-naver.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.btn.naver span {
  display: inline-flex;
  line-height: 20px;
  vertical-align: top;
}

.btn.account {
  display: block;
  height: 33px;
  line-height: 21px;
  padding: 5px 10px;
  background-color: #8EC28A;
  font-size: 12px;
  border: 1px solid #96C691;
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
  box-shadow: 5px 5px 17px 5px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 5px 5px 17px 5px rgba(0, 0, 0, 0.23);
}

.btn.account:hover {
  border-color: #444;
}

.btn.star::before {
  display: block;
  margin: 0 auto 5px auto;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  content: "\f005";
  width: 26px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  animation: pulse 1.5s infinite alternate;
}

.btn.star.true::before {
  color: #FFE810;
  background-color: #444;
  border-radius: 100%;
  border: 0px solid #aaa
}

.btn.star.false::before {
  color: #aaa;
}

.btn.star.false:hover::before {
  color: #FFE810 !important;
}

.btn.star.false:disabled:hover::before {
  color: #aaa !important
}

.btn.star.false:disabled:hover span {
  color: #aaa !important
}

.btn.close {
  position: absolute;
  top: 7px;
  right: 18px;
  padding: 0;
  margin: 0;
  color: #fff;
  border: 0;
  background-color: transparent;
  font-size: 23px;
  font-weight: 700;
  cursor: pointer;
}

.btn.close:hover {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

footer .footer-modal {
  position: fixed;
  bottom: 25px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  padding: 15px;
  text-align: left;
  z-index: 99;
  cursor: pointer;
}

footer .footer-modal {
  opacity: 0.6;
}

footer .footer-modal span {
  display: block;
  margin: 3px 0;
}

footer .footer-modal:hover {
  opacity: 1;
  color: #000;
  color: #54984e;
}

#www-contents {
  padding: 0;
}

#www-contents>.error {
  display: block;
  margin: 30px auto;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: #444;
  text-align: center
}

.modal-error {
  position: fixed;
  top: 20%;
  left: 50%;
  width: 200px;
  padding: 10px;
  margin: 0 0 0 100px;
  background-color: #b1686e;
  border: 1px solid #DA5136;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 99999;
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  background-color: rgba(4, 4, 4, 0.5);
  z-index: 99999;
}

.section-map {
  position: relative;
  display: inline-block;
  width: calc(100vw - 500px);
  height: 100vh;
}

.map-control {
  position: absolute;
  top: 33px;
  left: 515px;
  z-index: 1;
}

.map-control ul {
  display: block;
}

.map-control ul li {
  margin: 10px 0;
}

.map-control ul li:last-child {
  margin-bottom: 0
}

.map-control ul li .btn {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  border-radius: 100%;
  border: 0px solid #eee;
  ;
  font-size: 22px;
  color: #24b47e;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
  background-color: #fff;
  text-align: center;
}

.map-control ul li .btn:active,
.map-control ul li .btn:hover {
  color: #24b47e;
  border-color: #24b47e;
}

.map-control ul.zoom-control li .btn.btn.in {
  background-color: #24b47e;
  color: #fff;
}

.map-control ul.zoom-control li .btn.btn.out {
  background-color: #24b47e;
  color: #fff;
}

.map-control ul.zoom-control li .btn.btn:hover {
  border-color: #fff;
  color: #fff;
}

.map-control ul li .btn:hover span {
  display: block;
  opacity: 1;
  color: #24b47e;
}

.map-control ul li .btn span {
  /* display: none; */
  position: absolute;
  top: 3px;
  right: -110px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  background-color: #fff;
  color: #444;
  font-size: 13px;
  font-weight: 900;
  border: 2px solid #fff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.overlay-marker {
  position: relative;
  width: 20px;
  height: 20px;
  border: 6px solid #b7f5bc;
  background-color: #24b47e;
  border-radius: 100%;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, .25), 0 3px 7px -3px rgba(0, 0, 0, .3);
  animation: pulse .9s infinite alternate;
}

.overlay-info {
  position: relative;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}

.overlay-info .title {
  display: block;
  text-align: center;
  background: #fff;
  color: #444;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
}

.overlay-info .badge {
  position: absolute;
  top: 8px;
  right: -10px;
  color: #F8CF00;
  font-size: 16px;
  background-color: #444;
  width: 27px;
  height: 27px;
  line-height: 27px;
  text-align: center;
  border-radius: 100%;
}

.overlay-info:before {
  content: '';
  position: absolute;
  z-index: 1;
  position: relative;
  display: block;
  border-radius: 100%;
  background-color: #1A7D0C;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 50%;
  margin-left: -10px;
  border: 1px solid #fff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
}

.overlay-info.od:before {
  background-color: #bb3b22;
}

.overlay-info.gz:before {
  background-color: #023E87;
}

.overlay-info.tu:before {
  background-color: #F6AC0E;
}

.overlay-info.sg:before {
  background-color: #584C9E;
}

.overlay-info.xg:before {
  background-color: #006ce6;
}

.overlay-info.ct:before {
  background-color: #FF7810;
}

.overlay-info.qe:before {
  background-color: #1B1B1B;
}

.overlay-info.ok:before {
  background-color: #DA002A;
}

.overlay-info.gt:before {
  background-color: #0076EE;
}

.overlay-info.bv:before {
  background-color: #9C7C31;
}

.overlay-info.selected,
.overlay-info:hover {
  opacity: 1;
}

.overlay-info:hover:before {
  opacity: 1;
}

.overlay-info.selected .badge,
.overlay-info:hover .badge {
  color: #444;
  background-color: #F8CF00;
  border-color: #F8CF00;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.overlay-info.selected:before {
  animation: heartbeat 3s infinite alternate;
  transform-origin: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .62);
  border: 0;
}

.overlay-info.selected .title,
.overlay-info:hover .title {
  color: #fff;
  background-color: #24b47e;
  border-color: #444;
  z-index: 99999;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.section-contents {
  position: relative;
  display: inline-block;
  height: 100vh;
  /* display: flex; */
  width: 500px;
  vertical-align: top;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .62);
  z-index: 9999;
}

.sidebar {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #fff;
}

.search-form {
  position: relative;
  display: flex;
  padding: 5px;
}

.search-form form {
  position: relative;
  display: flex;
  flex: 1;
  /* width: 95%; */
  height: 45px;
  margin: 0 auto;
  padding: 0px;
  border: 1px solid #eee;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  /* box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; */
  /* vertical-align: middle; */
}

.search-form form label {
  display: none
}

.search-form input:active,
.search-form input:focus,
.search-form input {
  /* text-indent:5px;  */
  /* background-color:transparent;width:100%;height:30px;outline:0;border:0;padding:0 40px 0 0;margin:0;line-height:30px; */
  display: flex;
  height: 45px;
  flex: 1;
  padding: 10px 15px;
  border: 0;
  outline: 0;
  font-size: 15px;
  background-color: transparent;
}

.search-form input:focus {
  color: #4D8802;
}

.search-form button:hover,
.search-form button {
  display: flex;
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  /* justify-content: center; */
  /* position:absolute; */
  /* top:3px;right:3px; */
  background-color: transparent;
  border: 0px;
  height: 40px;
  width: 50px;
  color: #24b47e;
  font-size: 19px;
  /* border-radius: 10px; */
  /* text-align: center; */
  /* vertical-align: middle; */
  padding: 0;
}

.search-form button:disabled {
  color: #ddd;
}

.search-form-option {
  display: block;
  position: relative;
  margin: 0;
  padding: 5px 7px 4px 12px;
  /* background-color:#fafafa; */
  /* border-bottom:1px solid #eee; */
}

.search-form-option h2 {
  display: inline-block;
  font-size: 10px;
  line-height: 27px;
  font-weight: 700;
  vertical-align: bottom;
  color: #444;
  text-indent: 1px;
}

.search-form-option h2 span {
  font-weight: 700;
  font-size: 15px;
  color: #24b47e;
}

.search-form-option input {
  display: block;
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0;
  padding: 0;
  width: 15px;
  height: 15px;
  opacity: 0;
}

.search-form-option input[type="checkbox"]+label:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  line-height: 24px;
  margin-right: 5px;
  border: 3px solid #aaa;
  border-radius: 100%;
  vertical-align: middle;
}

.search-form-option input[type="checkbox"]:checked+label:before {
  content: "\f00c";
  display: inline-block;
  color: #fff;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 11px;
  line-height: 12px;
  transition: transform .3s ease-in-out;
  border: 2px solid #444;
  background-color: #444;
  text-align: center;
}

.search-form-option .btn {
  float: right;
  height: 28px;
  padding: 5px 10px;
  margin-left: 5px;
  border: 1px solid #1CB06D;
  background-color: #fff;
  border-radius: 5px;
  color: #1CB06D;
  font-weight: 700;
  font-size: 14px;
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08) */
}

.search-form-option .btn i {
  margin-right: 5px;
}

.search-form-option .btn.btn-map {
  display: none
}

.search-form-option .btn.btn-search i {
  margin: 0
}

.search-form-option .btn.true {
  background-color: #1CB06D;
  color: #fff;
}

.search-list {
  display: flex;
  flex: 1;
  /* position: relative; */
  padding: 0;
  -ms-overflow-style: none;
  overflow: auto;
  /* border: 1px solid #eee; */
  /* border-width: 0; */
}

.search-list ul {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  list-style: none;
  padding: 0 0 0px 0;
  margin: 0 0 0px 0;
  overflow: scroll;
}

.search-list ul li {
  display: block;
  position: relative;
  margin: 0px;
}

.search-list ul li.empty {
  padding: 20px 0px;
  text-align: center;
  color: #444;
  font-size: 15px;
  font-weight: 700;
  border: 0;
}

.search-list ul li.btn {
  margin: 20px 0 20px 0;
  text-align: center;
}

.search-list ul li.btn .btn-refresh {
  position: relative;
  padding: 10px 15px;
  margin: 0px auto;
  border-radius: 5px;
  border: 1px solid #ddd;
  border: 0;
  font-size: 15px;
  color: #fff;
  font-weight: 900;
  background-color: #24b47e;
  text-align: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.search-list ul li.btn .btn-refresh i {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
}

.search-list ul li.btn .btn-refresh:hover {
  background-color: #8ec28a
}

.search-list ul li.loading {
  border: 0;
}

.search-list ul li.loading .icon-loading {
  margin: 10px auto;
}

.search-list ul li .btn {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 10px;
  margin: 0px 0;
  border: 0;
  background-color: #fff;
  text-align: left;
  border-bottom: 1px solid #f9f9f9;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
  align-items: center;
}

.search-list ul li .btn.active,
.search-list ul li .btn:hover {
  background-color: #f2f8f1;
}

.search-list ul li .btn .title {
  display: flex;
  flex-direction: row;
}

.search-list ul li .btn .title .name {
  font-weight: 700;
  font-size: 16px;
  color: #444;
}

.search-list ul li .btn .title .badge {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}

.badge.ttype {
  font-size: 14px;
  color: #4D8802;
  font-weight: 700
}

.badge.ttype.od {
  color: #bb3b22
}

.badge.ttype.gz {
  color: #023E87
}

.badge.ttype.tu {
  color: #F6AC0E
}

.badge.ttype.sg {
  color: #584C9E
}

.badge.ttype.ct {
  color: #FF7810
}

.badge.ttype.xg {
  color: #006ce6
}

.badge.ttype.qe {
  color: #1B1B1B
}

.badge.ttype.ok {
  color: #DA002A
}

.badge.ttype.gt {
  color: #0076EE
}

.badge.ttype.bv {
  color: #9C7C31
}

.badge.ttype.cc {
  color: #1CB06D
}

.badge.ttype:after {
  content: '';
  height: 10px;
  width: 10px;
  margin: 0 0 0 5px;
  border-radius: 100%;
  background-color: #023E87;
  align-self: center;
}

.badge.ttype.tu:after {
  background-color: #023E87
}

.badge.ttype.tu:after {
  background-color: #F6AC0E
}

.badge.ttype.sg:after {
  background-color: #584C9E
}

.badge.ttype.xg:after {
  background-color: #006ce6
}

.badge.ttype.ct:after {
  background-color: #FF7810
}

.badge.ttype.qe:after {
  background-color: #1B1B1B
}

.badge.ttype.od:after {
  background-color: #bb3b22
}

.badge.ttype.ok:after {
  background-color: #DA002A
}

.badge.ttype.gt:after {
  background-color: #0076EE
}

.badge.ttype.bv:after {
  background-color: #9C7C31
}

.badge.ttype.cc:after {
  background-color: #1CB06D
}

.search-list ul li .btn span.desc {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  color: #444;
}

.search-list ul li .btn .badges {
  display: block;
  margin: 5px 0 0 0;
}

.badges .badge {
  display: inline-block;
  padding: 4px 7px;
  margin-right: 5px;
  vertical-align: top;
  border-radius: 7px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  /* line-height: 12px; */
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08); */
}

.badges .badge.update {
  background-color: #1CB06D;
  color: #fff;
}

.badges .badge.new {
  background-color: #5D67E1;
  color: #fff;
}

.badges .badge.review {
  background-color: #F7DB4A;
  color: #444;
}

.badges .badge.letter {
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 900;
  line-height: 20px;
  border-radius: 100% !important;
}

.badges .badge.bolt {
  background-color: transparent;
  color: #F8CF00;
  border-radius: 100%;
  font-size: 22px;
  box-shadow: none;
  animation: heartbeat 3s infinite alternate;
}

.badges .badge.bolt-txt {
  background-color: #F8CF00;
  color: #444;
}

.badges .badge.open {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.event {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.valet {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.parking {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.price {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.qving {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.lesson {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.profiles {
  background-color: #EBEBEB;
  color: #444;
}

.badges .badge.direct {
  background-color: #EBEBEB;
  color: #444;
}

.search-list ul li .btn .img {
  display: flex;
  margin-right: 15px;
}

.search-list ul li .btn .img img {
  width: 90px;
  height: 90px;
  opacity: 1;
  border-radius: 4px;
}

.search-list ul li .btn .img.no-img {
  width: 90px;
  height: 90px;
  opacity: 1;
  border-radius: 10px;
  background: url('./images/icon-ttcc.png');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fafafa;
}

.search-list ul li .btn .img.no-img.XG {
  background-image: url('./images/icon-xg.png');
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.GZ {
  background-image: url('./images/icon-gz.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.GZP {
  background-image: url('./images/icon-gzp.jpeg');
  background-color: #fff;
  background-size: 100% 100%;
}

.search-list ul li .btn .img.no-img.GDR {
  background-image: url('./images/icon-gdr.png');
  background-color: #fff;
  background-size: 85% auto;
}

.search-list ul li .btn .img.no-img.TU {
  background-image: url('./images/icon-tu.png');
  background-color: #fff;
  background-size: 60px auto;
}

.search-list ul li .btn .img.no-img.SG {
  background-image: url('./images/icon-sg.png');
  background-size: 100% 100%;
}

.search-list ul li .btn .img.no-img.SDR {
  background-image: url('./images/icon-sdr.png');
  background-size: 100% 100%;
}

.search-list ul li .btn .img.no-img.XG {
  background-image: url('./images/icon-xg.png');
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.CT {
  background-image: url('./images/icon-ct.png');
  background-color: #444;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.QE {
  background-image: url('./images/icon-qed.png');
  background-color: #262626;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.BV {
  background-image: url('./images/icon-bv.jpg');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.OK {
  background-image: url('./images/icon-udr.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.GT {
  background-image: url('./images/icon-gt.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.RX {
  background-image: url('./images/icon-rx.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.TPZ {
  background-image: url('./images/icon-tpz.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn .img.no-img.TS {
  background-image: url('./images/icon-ts.png');
  background-color: #fff;
  background-size: 55px auto;
}

.search-list ul li .btn:hover .img img {
  opacity: 1;
  border: 0;
}

.search-list ul li .btn:hover .img.no-img {
  opacity: 1;
  border: 0px solid transparent;
}

.search-control {
  position: relative;
  display: block;
  padding: 5px 0;
  text-align: center;
}

.search-control .ul-tags {
  display: block;
}

.search-control .btn.toggle {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: center;
  font-size: 13px;
}

.search-control li {
  position: relative;
  display: inline-block;
  margin: 2px;
}

.search-control li label {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #eee;
  color: #111;
  border-radius: 10px;
  white-space: nowrap;
  margin: 0px;
  line-height: 17px;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  padding: 5px 9px;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06);
}

.search-control li label:hover {
  background-color: #24b47e;
  color: #fff;
}

.search-control li label:before {
  width: 11px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 0 6px 0 0;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

/* .search-control li label.qving {color:#79BDBE;font-weight:bold;} */
.search-control li input[type="checkbox"]:checked+label.qving {
  background-color: #79BDBE;
  color: #fff;
  border-color: #79BDBE;
}

.search-control li label.gz:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #023E87;
}

.search-control li label.tu:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #F6AC0E;
}

.search-control li label.xg:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #006ce6;
}

.search-control li label.sg:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #584C9E;
}

.search-control li label.od:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #bb3b22;
}

.search-control li label.ct:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #FF7810;
}

.search-control li label.qe:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #1B1B1B;
}

.search-control li label.ok:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #DA002A;
}

.search-control li label.gt:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #0076EE;
}

.search-control li label.bv:before {
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 6px;
  background-color: #9C7C31;
}

.search-control li input[type="checkbox"]:checked+label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

.search-control li input[type="checkbox"]:checked+label {
  border: 1px solid #444;
  background-color: #444;
  color: #fff;
  transition: all .2s;
}

.search-control li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.sidebar-top {
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  background-color: #444;
  color: #fff;
}

.sidebar-footer {
  position: relative;
  display: block;
  width: auto;
  padding: 10px;
  /* background-color:#f1f1f1; */
  /* border-bottom: 1px solid #ddd; */
  text-align: left;
}

.sidebar-footer h3 {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: #444;
}

.sidebar-footer .ul-footer li h1 {
  color: #24b47e;
  font-weight: 900;
}

.sidebar-footer .ul-footer li h1:before {
  content: '';
  display: inline-block;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  padding: 0;
  margin-right: 6px;
  background-color: #24b47e;
  vertical-align: top;
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08); */
}

.sidebar-footer .ul-footer li {
  list-style-type: none;
  display: block;
  color: #444;
  font-size: 12px;
}

.sidebar-footer .ul-footer li a:hover {
  color: #4D8802
}

.sidebar-footer .ul-btns {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-align: center;
}

.sidebar-footer .ul-btns li {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  font-size: 0;
  vertical-align: bottom;
}

.sidebar-footer .ul-btns .btn {
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 26px;
  border-radius: 0px;
  border: 0;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
}

.sidebar-footer .ul-btns li:last-child .btn {
  border-radius: 0 5px 0 0;
}

.sidebar-footer .ul-btns li:first-child .btn {
  border-radius: 5px 0px 0 0;
}

.sidebar-footer .ul-btns .btn.btn-account {
  background-color: #6eb168;
}

.sidebar-footer .ul-btns .btn.btn-toggle {
  background-color: #8EC28A;
}

.sidebar-footer .ul-btns .btn.btn-kakao {
  background-color: #FECB29;
}

.sidebar-footer .ul-btns .btn.btn-clients {
  width: 40px;
  background-color: #6b94af;
  color: #fff;
}

.sidebar-footer .ul-btns .btn:hover {
  color: #444;
}

.sidebar-menu {
  display: flex;
  flex-direction: row;
  flex: 0;
  padding: 0 0px;
  background-color: #fff;
  border-top: 1px solid #eee;
  /* z-index: 9999; */
}

.sidebar-menu .ul-menu {
  display: flex;
  flex: 1;
  position: relative;
}

.sidebar-menu .ul-menu {
  display: flex;
  /* position: relative; */
  /* justify-content: space-between; */
}

.sidebar-menu .ul-menu li {
  display: flex;
  flex: 1;
  /* width: 100%; */
  padding: 0;
  /* box-sizing: border-box; */
  border: 0;
}

.sidebar-menu .ul-menu li:nth-child(2) {
  border: 1px solid #eee;
  border-width: 0 1px 0 1px;
}

.sidebar-menu .ul-menu li a,
.sidebar-menu .ul-menu li button {
  display: flex;
  flex: 1;
  /* width: 100%; */
  padding: 15px 10px;
  margin: 0;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #444;
  font-weight: 900;
  /* font-size: 16px; */
  border: 0;
  border-radius: 0px;
}

/* .sidebar-menu .ul-menu li .btn {
  background-color: #fff;
  border-bottom: 0px solid #ddd;
} */

/* .sidebar-menu .ul-menu li .btn i {
  display: inline-block;
  font-size: 19px;
  vertical-align: middle;
} */

/* .sidebar-menu .ul-menu li:last-child .btn {color:#6872E5;} */
.sidebar-menu .ul-menu li .btn span {
  display: inline-block;
  margin: 0 0 0 0;
  font-weight: 700;
  font-size: 16px;
  /* vertical-align: middle; */
}

.sidebar-menu .ul-menu li:hover .btn,
.sidebar-menu .ul-menu li.true .btn {
  background-color: #24b47e;
  color: #fff;
  border-bottom: 0px solid #54984e;
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08) */
}

.sidebar-menu .ul-menu li .logo-txt {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #8EC28A;
  /* color:#fff; */
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
}

.sidebar-menu .ul-menu li .logo-txt span {
  color: #fff;
  font-size: 15px;
  margin-left: 5px;
}

.search-info .section-tab {
  display: block;
  padding: 0;
  margin: 20px 0;
}

.search-info .section-tab .message {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  justify-content: center;
  color: #444;
  text-align: center;
  margin: 0 auto;
  /* padding: 20px 10px; */
}

.search-info .section-tab .message .span-msg {
  display: block;
  padding: 10px;
  margin: 0;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

.search-info .section-tab .message .span-msg.error {
  background-color: #FAFAFA;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  border-radius: 3px;
  border-bottom: 2px solid #ddd
}

.search-info .section-tab .message .span-msg.error span {
  color: #24b47e;
  font-weight: bold;
  font-size: 15px;
}

.search-info table {
  width: 100%;
  font-size: 13px;
  line-height: 19px;
  background-color: transparent;
  margin-top: 10px;
  table-layout: fixed;
}

.search-info table.hidden {
  display: none
}

.search-info table tr td {
  padding: 10px 15px;
  border-bottom: 1px solid #adc4d3;
  background-color: transparent;
  margin: 1px;
  color: #444;
  overflow-wrap: break-word;
}

.search-info table tr td.label {
  min-width: 100px;
  width: 100px;
  font-weight: 700
}

.search-info table tr td.label i {
  margin-right: 10px;
}

.search-info.visual {
  position: fixed;
  left: 00px;
  top: 0px;
  bottom: 0px;
  width: 570px;
  height: auto;
  padding: 20px 20px 20px 20px;
  background-image: linear-gradient(to top, #BED0DC 0%, #BED0DC 100%);
  border-radius: 0px;
  -ms-overflow-style: none;
  z-index: 9999;
  overflow-y: scroll;
  -webkit-box-shadow: 5px 5px 17px 5px rgba(0, 0, 0, 0.23);
  box-shadow: 5px 5px 17px 5px rgba(0, 0, 0, 0.23);
  color: #444;
}

.search-info .search-info-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #cfdce5;
  padding: 10px 13px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.search-info.visual h2 {
  display: flex;
  font-size: 32px;
  line-height: 35px;
  font-weight: 700;
  color: #444;
}

.search-info .search-info-control .btn-close {
  display: flex;
  color: #444;
  background-color: transparent;
  border: 0;
  font-weight: 700;
  cursor: pointer;
}

.search-info .search-info-control .btn-close {
  font-size: 29px;
}

.search-info .search-info-control .btn-close:hover {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.search-info .btn-close:hover i {
  color: #444
}

.search-info.visual .icon-system {
  display: block;
  width: 150px;
  height: 70px;
  margin-bottom: 10px;
  color: transparent;
  border-radius: 5px;
  background-color: #fff;
}

.search-info.visual .ul-info {
  display: block;
  margin: 0;
}

.search-info.visual .ul-info li {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 5px 0;
}

.search-info.visual .ul-info li span {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  color: #444;
  word-break: keep-all;
}

.search-info.visual .ul-info li span.false {
  color: #777;
  font-size: 14px;
}

.search-info.visual .ul-info li a.btn,
.search-info.visual .ul-info li button.btn {
  display: inline-flex;
  width: 35px;
  min-width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  background-color: #24b47e;
  border: 1px solid #24b47e;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;
}

.search-info.visual .ul-info li .btn i {
  display: block;
  margin: 0px;
  line-height: 33px;
  font-size: 15px;
}

.search-info.visual .ul-info li .btn:hover {
  border-color: #4D8802;
}

.search-info.visual .ul-info li a.btn.false {
  opacity: 0.7
}

.search-info.visual .info-wrap {
  display: block;
  margin: 0 0 15px 0;
  text-align: center
}

.search-info.visual .div-info {
  position: relative;
  display: inline-table;
  width: 95px;
  height: 95px;
  margin: 10px;
  border-radius: 100%;
  background-color: #fff;
  vertical-align: top;
}

.search-info.visual .div-info p {
  width: 100%;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #4D8802;
}

.search-info.visual .div-info p span {
  display: block;
}

.search-info.visual .div-info.s1 p span {
  font-size: 14px;
}

.search-info.visual .div-info.s2 p span {
  font-size: 13px;
}

.search-info.visual .div-info.s3 p span {
  font-size: 12px;
}

.search-info.visual .div-info>label {
  position: absolute;
  left: 0;
  top: -2px;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 13px;
  background-color: #24b47e;
  color: #fff;
  box-sizing: border-box;
}

.search-info.visual .div-info.r1>p {
  font-size: 17px;
}

.search-info.visual .div-info.numeric>p {
  font-size: 17px;
}

/* .search-info.visual .div-info.info-qving {border:2px solid #79BDBE;background:url('./images/icon-qving.png') no-repeat #fff;background-size: contain;background-position: center bottom;}
.search-info.visual .div-info.info-qving label {width:100%;background-color:#79BDBE;color:#444}
.search-info.visual .div-info.info-qving p {color:#79BDBE;line-height:20px;} */

.search-info.visual .div-info2.info-hours,
.search-info.visual .div-info2.info-prices,
.search-info.visual .div-info2.info-note {
  display: block;
  width: auto;
  height: auto;
  margin: 0px 0 20px 0;
  border-radius: 5px;
  border: 0;
  overflow-wrap: break-word;
}

.search-info.visual .div-info2>label {
  display: inline-block;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  background-color: #8EC28A;
  color: #fff;
}

.search-info.visual .div-info2.info-hours>label,
.search-info.visual .div-info2.info-prices>label,
.search-info.visual .div-info2.info-note>label,
.search-info.visual .div-img.info-img label {
  display: block;
  padding: 5px 10px;
  margin: 0 0 15px 0;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #b1c3d0;
  color: #444;
  border-bottom: 1px solid #a1b6c7;
}

.search-info.visual .div-info2.info-note>.row {
  display: block;
  padding: 15px;
  border-radius: 3px;
  text-align: left;
  color: #444;
  background-color: #fff;
  color: #444;
}

.search-info.visual .div-info2.info-note .empty {
  color: #777;
}

.search-info.visual .div-info2.info-note>.row p {
  line-height: 25px;
  font-size: 15px;
  font-weight: 400;
}

.search-info.visual .div-info2.info-hours {
  padding: 0;
  background-color: transparent;
}

.search-info.visual .div-info2.info-hours ul {
  padding: 0 5px;
}

.search-info.visual .div-info2.info-hours li {
  display: flex;
  line-height: 25px;
  padding: 2px 0px;
  /* text-align: left; */
  text-indent: 5px;
  font-size: 16px;
  color: #444;
  /* margin: 0 auto 5px 0; */
  /* border-radius: 3px; */
}

.search-info.visual .div-info2.info-hours li:last-child {
  margin: 0
}

.search-info.visual .div-info2.info-hours li.active {
  padding: 5px 0;
  background-color: #fff;
}

.search-info.visual .div-info2.info-prices ul li label,
.search-info.visual .div-info2.info-hours li label {
  display: inline-block;
  width: 80px;
  padding: 0;
  color: #444;
  /* font-weight: 700; */
}

/* .search-info.visual .div-info2.info-hours li label:before {
  display: inline-block;
  margin-right: 10px;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #91aabf;
} */

.search-info.visual .div-info2.info-hours li span {
  padding: 0;
  margin: 0;
  color: #444;
  font-weight: normal;
  font-size: 18px;
}

.search-info.visual .div-info2.info-hours li span:last-child::before {
  content: '-';
  margin: 0 15px 0 15px;
}

.search-info.visual .div-info2.info-hours li span.empty {
  font-size: 13px;
  color: #777;
}

.search-info.visual .div-info2.info-hours li span.empty::before {
  content: none
}

.search-info.visual .div-info2.info-prices ul {
  display: flex;
  flex-direction: column;
}

.search-info.visual .div-info2.info-prices ul li {
  display: flex;
  line-height: 30px;
  padding: 0;
  margin-bottom: 5px;
  text-align: center;
  align-content: center;

}

.search-info.visual .div-info2.info-prices ul li.thead {
  padding: 0px 0;
  border-bottom: 0px solid #ddd;
  background-color: transparent;
  font-weight: bold;
  font-size: 13px;
}

.search-info.visual .div-info2.info-prices ul li.thead div {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
}

.search-info.visual .div-info2.info-prices ul li.thead div:first-child {
  width: 60px;
  padding: 0 5px;
}

.search-info.visual .div-info2.info-prices ul li.row {
  align-items: center;
  margin-bottom: 10px;
}

.search-info.visual .div-info2.info-prices ul li div {
  padding: 0 30px 0 0;
  font-weight: normal;
  font-size: 16px;
}

.search-info.visual .div-info2.info-prices ul li div:first-child {
  padding-left: 10px;
}

.search-info.visual .div-info2.info-prices ul li div i {
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #24b47e;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.search-info.visual .div-info2.info-prices ul li .txt label {
  display: block;
  width: auto;
  line-height: 18px;
  font-size: 12px;
  font-weight: bold;
}

.search-info.visual .div-info2.info-prices ul li .txt span {
  display: block;
  line-height: 19px;
}

.search-info.visual .div-info2.info-prices ul li .txt span:nth-child(2) {
  text-decoration: line-through;
  color: #999;
  font-size: 13px;
}

.search-info.visual .div-info2.info-prices ul li .txt span:last-child {
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  color: #24b47e;
}

.search-info.visual .div-info2 ul li.empty {
  line-height: 35px;
  font-size: 13px;
  vertical-align: top;
  color: #777;
  justify-content: center;
}

.search-info.visual .div-info2.info-ad>.ad-desc,
.search-info.visual .div-info2>span {
  display: inline-block;
  margin: 5px;
  padding: 8px 10px;
  font-weight: 700;
  border-radius: 10px;
  font-size: 14px;
  background-color: #fff;
  color: #4D8802;
}

.search-info.visual .info-ad label {
  display: block;
  width: auto;
  margin: 0 auto 10px auto;
}

.search-info.visual .info-ad a.link-ad {
  display: block;
  padding: 10px;
  margin: 15px auto 0 auto;
  font-size: 12px;
  background-color: #6b94af;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
}

.search-info.visual .div-info2.info-ad .ad-desc span {
  display: block;
  line-height: 20px;
}

.search-info.visual .div-img.info-img .empty {
  margin-bottom: 40px;
  line-height: 35px;
  font-size: 13px;
  vertical-align: top;
  text-align: center;
  color: #777;
}

.search-info.visual .div-img.info-img img {
  display: block;
  margin: 0 auto 0px auto;
  width: 100%;
  border-radius: 5px;
  opacity: 1;
  border: 0;
  border-bottom: 2px solid #8cacc1;
}

.search-info.visual .div-img.info-img img.no-img {
  width: 100px;
  background-color: #fff;
  opacity: 0.9;
}

.search-info.visual .div-img.info-img .slick-slider {
  margin: 10px auto;
  max-width: 500px;
}

.search-info.visual .div-img.info-img .slick-slider .slick-arrow:before {
  font-size: 30px;
  opacity: 1;
}

.search-info.visual .div-img.info-img .slick-slider .slick-arrow.slick-prev {
  left: -7px;
  z-index: 99;
}

.search-info.visual .div-img.info-img .slick-slider .slick-arrow.slick-next {
  right: 2px;
  z-index: 99;
}

.search-info.visual .div-row {
  text-align: center
}

.search-info.visual .div-row a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 10px;
  margin: 0 auto;
  font-size: 15px;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08); */
}

.search-info.visual .div-row a i {
  padding: 0px;
  margin-right: 5px;
}

.search-info.visual .div-row a:hover {
  font-weight: bold;
  color: #1CB06D
}

.search-info h4 {
  display: block;
  font-weight: 500;
  font-size: 13px;
  margin: 10px 0;
  padding: 10px;
  background-color: #f1f1f1;
  color: #444;
}

.search-info .ul-tabs {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.search-info .ul-tabs.social {
  justify-content: space-around;
  overflow: visible;
}

.search-info .ul-tabs li {
  display: flex;
  position: relative;
  /* margin: 0px auto; */
  /* vertical-align: top; */
  /* width: 90px; */
  /* box-sizing: border-box; */
  /* overflow: visible; */
}

.search-info .ul-tabs li.tab {
  width: 100%;
}

.search-info .ul-tabs li>button {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: transparent;
  background-color: #C8D7E1;
  color: #888;
  cursor: pointer;
  border: 0;
}

.search-info .ul-tabs li:first-child button {
  border-radius: 3px 0 0 0;
}

.search-info .ul-tabs li:last-child button {
  border-radius: 0 3px 0 0;
}

.search-info .ul-tabs li .btn span {
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  color: #444;
}

.search-info .ul-tabs li .btn>i {
  display: block;
  margin: 0 auto 5px auto;
  font-size: 17px;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.search-info .ul-tabs li .btn.true,
.search-info .ul-tabs li:hover .btn {
  color: #444;
  font-weight: 900
}

.search-info .ul-tabs li .btn .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 10px;
  font-size: 11px;
  background-color: #8a8ec2;
  color: #fff;
  border-radius: 100%;
  vertical-align: top;
  border: 0;
}

.search-info .ul-tabs li .btn .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 100%;
  background-color: #F8CF00;
  border: 1px solid #F6AC0E;
  animation: heartbeat 3s infinite alternate;
}

.search-info .ul-tabs.social {
  display: flex;
  flex-direction: row;
}

.search-info .ul-tabs.social>li {
  width: auto;
  height: auto;
  margin: 0;
}

.search-info .ul-tabs.social>li .btn {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
}

.search-info .ul-tabs.social>li .btn.star::before {
  margin: 0 11px 0 0;
}

.search-info .ul-tabs.social>li .btn span {
  font-size: 15px;
  color: #444;
  font-weight: 500;
}

/* .search-info .ul-tabs.social > li .btn.share:hover i {color:#3B1E1E;} */
.search-info .ul-tabs.social>li .btn.star.true::before {
  width: 23px;
  height: 23px;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  animation: none;
}

.search-info .ul-tabs.social>li .btn.star.true span {
  font-weight: 700
}

.search-info .ul-tabs.social>li .btn.share i {
  font-size: 13px;
  color: #3B1E1E;
  background-color: #FFE810;
  border-radius: 5px;
  margin: 0 10px 0 0;
}

.search-info .ul-tabs.horizontal {
  margin: 20px 0 0 0;
}

.search-info .ul-tabs.horizontal li.tab:first-child .btn {
  border-radius: 4px 0 0 4px;
}

.search-info .ul-tabs.horizontal li.tab:last-child .btn {
  border-radius: 0 4px 4px 0;
}

.search-info .ul-tabs.horizontal li .btn {
  /* height: 35px; */
  border-bottom: 0px solid #ddd;
  color: #666;
}

.search-info .ul-tabs.horizontal li .btn:hover,
.search-info .ul-tabs.horizontal li .btn.true {
  /* border-bottom-color: #fff; */
  background-color: #fff;
  color: #444;
}

.search-info .ul-tabs.horizontal li .btn i {
  display: none
}

.section-events {
  position: relative;
  padding: 0px;
}

.section-events>h3 {
  display: table;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  background-color: #8cacc1;
  color: #fff;
}

.event-list {
  padding: 10px 0;
}

.event-form span.span-alert,
.event-list span.span-alert {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  background-color: #cfdce5;
  border-radius: 10px;
  color: #444;
}

.event-list .event-info {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px;
  background-color: #cfdce5;
  border-radius: 4px;
}

.event-list .event-info+.event-info {
  margin-top: 20px;
}

.event-list .event-info.completed .ul-event-info {
  opacity: 0.4
}

.event-info .col {
  position: relative;
  vertical-align: top;
}

.event-info .col h3 {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #56AB6D;
  border-radius: 4px;
  border: 0;
  text-align: center;
  line-height: 30px;
}

.event-info .ul-event-info {
  vertical-align: top;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.event-info .ul-event-info li {
  flex: 0 1 48%;
  margin: 3px;
  text-align: left;
  font-size: 15px;
  line-height: 30px;
  background-color: transparent;
  color: #444;
}

.event-info .ul-event-info li a {
  display: inline-block;
  color: #4D8802;
  font-size: 15px;
}

.event-info .ul-event-info li a:hover {
  color: #444;
}

.event-info .ul-event-info li label {
  display: inline-block;
  width: 60px;
  line-height: 30px;
  margin: 0 10px 0 0;
  color: #444;
  font-size: 14px;
  color: #444;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}

.event-info .event-info-control {
  display: flex;
  flex-direction: row;
}

.event-info .event-info-control button {
  display: block;
  padding: 5px 10px;
  margin-right: 10px;
  ;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
}

.event-info .event-info-control button i {
  margin-right: 5px;
}

.event-info .event-info-control button.btn.blue {
  background-color: #adc4d3;
  color: #fff;
  border: 1px solid #8cacc1;
}

.event-info .event-info-control button.btn.green {
  background-color: #8EC28A;
  color: #fff;
  border: 1px solid #8EC28A;
}

.event-info .event-info-control button.btn.red {
  background-color: #c28a8e;
  color: #fff;
  border: 1px solid #c28a8e;
  opacity: 0.5
}

.event-info .event-info-control button.btn.blue:hover {
  background-color: #9db8ca;
  color: #fff;
  opacity: 1;
}

.event-info .event-info-control button.btn.green:hover {
  border-color: #4D8802;
  color: #fff;
  opacity: 1;
}

.event-info .event-info-control button.btn.red:hover {
  border-color: #b1686e;
  color: #fff;
  opacity: 1;
}

.event-info .event-info-control>span {
  display: block;
  color: #b1686e;
  font-weight: 700;
  font-size: 15px;
  margin: 0px;
  text-indent: 10px;
}

.event-info .event-info-control button.btn.signin {
  margin: 0;
  font-weight: 700;
  box-shadow: none;
  -webkit-box-shadow: none
}

.event-form {
  display: block;
  margin: 10px 0 0px 0;
  padding: 10px 15px;
  background-color: #cfdce5;
  border-radius: 5px;
}

.event-form .btn.kakao {
  box-shadow: none;
  -webkit-box-shadow: none;
  margin: 5px auto 0 auto;
}

.event-form .row {
  display: block;
  margin: 10px auto;
  text-align: left;
}

.event-form .row .col {
  display: inline-block;
}

.event-form .row .col+.col {
  margin-left: 10px;
}

.event-form .row.msg {
  margin: 0;
}

.event-form .row.msg span.span-msg {
  display: block;
  padding: 0px 0px;
  color: #444;
  font-size: 16px;
  line-height: 33px;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
}

.event-form .row .col>label {
  display: table;
  line-height: 27px;
  padding: 0px 10px;
  margin: 0;
  color: #444;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background-color: #6CA973;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.event-form .row .col>input {
  display: inline-block;
  width: 280px;
  height: 35px;
  line-height: 35px;
  padding: 0px 10px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0 5px 5px 5px;
  font-size: 15px;
  color: #4D8802;
  font-weight: bold;
  text-align: left;
  box-sizing: border-box;
}

.event-form .row .col>input.inp-short {
  display: inline-block;
  width: 100px;
}

.event-form .row .col>input.inp-long {
  width: 200px;
}

.event-form .row .btn-event {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #24b47e;
  color: #fff;
  border-radius: 10px;
  font-weight: 900;
  font-size: 16px;
  border: 2px solid #24b47e;
}

.event-form .row .btn-event:disabled {
  background-color: #eee;
  color: #aaa;
  border: 0;
  border-bottom: 2px solid #ddd;
}

.event-form .row .btn-event:focus,
.event-form .row .btn-event:hover {
  border-color: #4D8802;
}

.event-form .row .btn-event:disabled:hover {
  border-color: #aaa;
}

.event-form .row.date ul {
  text-align: left;
}

.event-form .row.date ul li {
  position: relative;
  display: inline-table;
  margin: 0px;
}

.event-form .row.date ul li+li {
  margin-left: 10px;
}

.event-form .row.date ul li input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.event-form .row.date ul li input:active,
.event-form .row.date ul li input:focus {
  display: inline-block;
}

.event-form .row.date ul li label {
  display: inline-table;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: #eee;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.event-form .row.date ul li input:hover+label,
.event-form .row.date ul li input:checked+label,
.event-form .row.date ul li input:focus+label,
.event-form .row.date ul li input:active+label {
  font-weight: 900;
  color: #6CA973;
  background-color: #fff;
  border: 1px solid #6CA973;
  opacity: 1;
}

.event-form .row.date ul li input:hover+label:before,
.event-form .row.date ul li input:checked+label:before {
  display: block;
  position: absolute;
  left: 3px;
  top: -14px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 23px;
  content: "\f0e7";
  color: #F8CF00;
}

.event-form .row .col .select {
  width: 100%;
  padding: 10px;
  border: 0px solid #eee;
  border-radius: 0 5px 5px 5px;
  line-height: 14px;
  font-size: 13px;
  background-color: #fff;
  color: #4D8802;
}

.event-form .row .col .request {
  border-radius: 5px;
}

.section-lesson {
  position: relative;
  padding: 0px;
}

.section-lesson>h3 {
  display: block;
  width: 45px;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  background-color: #8cacc1;
  color: #fff;
}

.instructor-list {
  padding: 10px 0;
}

.instructor-list ul {
  display: flex;
  flex-wrap: wrap;
}

.instructor-list .card {
  position: relative;
  display: inline-block;
  flex: 0 50%;
  width: 100%;
  padding: 0 10px 10px 10px;
  vertical-align: top;
  box-sizing: border-box;
}

.instructor-list .profile {
  text-align: center;
  z-index: 99;
}

.instructor-list .photo {
  width: 170px;
  height: 170px;
  border-radius: 5px;
  border-bottom: 0px solid #91aabf;
  border-radius: 100%;
  margin: 0 auto;
}

.instructor-list .photo.empty {
  display: block;
  background-color: #91aabf;
}

.instructor-list .col {
  width: 100%;
  padding: 30px 10px 10px 10px;
  margin-top: -30px;
  background-color: #dfe8ee;
  border-bottom: 2px solid hsl(207, 26%, 66%);
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 98;
}

.instructor-list span {
  display: block;
  padding: 0px;
  text-align: center;
  margin: 8px 0;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
}

.instructor-list p {
  display: block;
  background-color: #e1e8ee;
  padding: 10px;
  border-radius: 5px;
  line-height: 24px;
  font-size: 14px;
}

.instructor-list ol {
  padding: 5px;
}

.instructor-list ol li {
  font-size: 14px;
  line-height: 27px;
  text-indent: 3px;
}

.reserve-form .row .col>label {
  background-color: #6b94af
}

.reserve-form .row.date ul li input:hover+label:before,
.reserve-form .row.date ul li input:checked+label:before {
  content: "\f274";
  color: #8EC28A;
  left: 0;
  top: -20px;
}

.reserve-form .row .btn-reserve {
  background-color: #6b94af;
  border-color: #6b94af
}

.reserve-form .row .btn-reserve:hover {
  border-color: #6b94af
}

.reserve-form .row .col.time .select {
  position: relative;
  display: inline-block;
  width: 80px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  color: #4D8802;
  font-weight: 700;
}

.reserve-form .row .col.long.agreement {
  padding: 10px;
}

.reserve-form .row .col.long.agreement>label {
  width: 200px;
  background-color: transparent;
  text-align: left;
  color: #444;
}

.reserve-form .row .col.long.agreement .txt {
  display: block;
  margin: 10px 0;
  font-size: 12px;
  line-height: 15px;
}

.reserve-form .row .col.long.agreement .checkbox {
  display: inline-block;
  margin: 0 10px 4px 0;
  padding: 0;
  vertical-align: middle;
}

.reserve-form .row .col.long.agreement>label.lbl-checkbox {
  width: auto
}

.reserve-form .row.msg span.span-msg {
  color: #6b94af;
}

/**************************************************/

.section-reviews {
  position: relative;
  padding: 0px;
}

.section-reviews h3 {
  display: table;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  background-color: #8cacc1;
  color: #fff;
}

.section-reviews .btn.kakao {
  margin: 0 auto;
}

.section-reviews.empty {
  padding: 10px;
  font-size: 12px;
  text-align: center;
  background-color: #cfdce5;
  border-radius: 10px;
  color: #444;
}

.section-reviews>ul {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  padding: 10px 0;
  gap: 15px;
  /* align-items: center; */
  justify-content: center;
}

.section-reviews ul li.empty {
  display: flex;
  flex: 1;
  padding: 20px;
  /* height: 40px; */
  /* line-height: 40px; */
  font-size: 16px;
  /* text-align: center; */
  background-color: #cfdce5;
  border-radius: 10px;
  color: #444;
  align-items: center;
  justify-content: center;
}

.section-reviews ul li.loading {
  position: relative;
  padding: 0px;
  height: 40px;
  margin: 0px auto;
}

.section-reviews ul li.loading .loading-wrap {
  margin: 0px 0 0 -10px;
  top: 0;
}

.section-reviews ul li.loading .icon-loading {
  width: 20px;
  height: 20px;
}

.section-reviews ul li.post {
  display: flex;
  flex: 1;
  position: relative;
  /* margin: 0 auto 10px auto; */
  padding: 15px 15px;
  font-size: 13px;
  background-color: #fafafa;
  border-radius: 10px;
}

/* .section-reviews ul li.post:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-right-color: #fafafa;
  border-left: 0;
  border-top: 0;
  margin-top: -17px;
  margin-left: -7px;
} */

.section-reviews ul li.post .post-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}

.section-reviews ul li .post-content .post-message {
  padding: 0;
  margin-bottom: 8px;
}

.section-reviews ul li .post-content .post-message p {
  padding: 0 0px;
  font-size: 17px;
  line-height: 23px;
  color: #444;
}

.section-reviews ul li .post-content .post-image {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 10px;
}

.section-reviews ul li .post-content .post-image img {
  max-width: 200px;
  max-height: 300px;
  border-radius: 5px;
  /* margin-right: 5px; */
}

.section-reviews ul li .post-content .post-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-reviews ul li .post-content .post-top .profile {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  /* gap: 5px; */
}

.section-reviews ul li .post-content .post-top .profile span {
  font-size: 16px;
  color: #999;
  /* border: 1px solid #eee;
  padding: 5px 8px;
  border-radius: 10px; */
}

.section-reviews ul li .post-content .post-top .profile span.name {
  font-weight: 700;
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
}

.section-reviews ul li .post-content .post-top .btn {
  display: flex;
  border: 0;
  background-color: transparent;
  font-size: 12px
}

.section-reviews ul li .post-content .post-top .btn.delete {
  display: flex;
  align-items: center;
  color: #de644c;
  font-size: 13px;
  /* background-color: #eee; */
  border-radius: 6px;
  border: 1px solid #de644c;
  padding: 3px 5px;
}

.section-reviews ul li .post-content .post-top .btn.delete:hover {
  color: #bb3b22
}

.section-reviews ul li .post-content .post-top .ratings {
  padding: 5px 8px;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #24b47e;
  color: #fff;
  display: flex;
  gap: 4px;
  /* background-color:#8a8ec2; */
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08); */
}

.section-posts {
  position: relative;
  padding: 0px;
}

.section-posts .post-control {
  display: block;
  padding: 5px 10px;
}

.section-posts .post-control .btn.btn-form {
  display: block;
  width: 100%;
  height: 30px;
  border: 0px solid #eee;
  color: #fff;
  background-color: #888;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 700;
}

.section-posts .post-control .btn.btn-form i {
  margin-left: 10px
}

.section-posts .post-control .btn.btn-form.true {
  background-color: #ddd
}

.section-posts h3 {
  display: block;
  width: 25px;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #8cacc1;
  color: #fff;
}

.section-posts .btn.kakao {
  margin: 0 auto;
}

.section-posts.empty {
  padding: 10px;
  font-size: 12px;
  text-align: center;
  background-color: #cfdce5;
  border-radius: 10px;
  color: #444;
}

.section-posts ul {
  position: relative;
  padding: 0 10px;
}

.section-posts ul li.empty {
  padding: 10px;
  font-size: 12px;
  text-align: center;
  background-color: #cfdce5;
  border-radius: 10px;
  color: #444;
}

.section-posts ul li.loading {
  position: relative;
  padding: 0px;
  height: 40px;
  margin: 20px auto;
}

.section-posts ul li.loading .loading-wrap {
  margin: 0px 0 0 -10px;
  top: 0;
}

.section-posts ul li.loading .icon-loading {
  width: 20px;
  height: 20px;
}

.section-posts ul li.post {
  position: relative;
  margin: 0px auto;
  padding: 15px 5px 0px 5px;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}

.section-posts ul li .post-content .post-breadcrumb {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px 10px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 10px;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.section-posts ul li.post:hover .post-breadcrumb {
  border: 1px solid #8EC28A;
  background-color: #96C691;
  color: #fff;
}

.section-posts ul li .post-content .post-breadcrumb li {
  display: inline-block;
  font-size: 12px;
  line-height: 14px
}

.section-posts ul li .post-content .post-breadcrumb li+li:before {
  content: '/';
  margin: 0 5px;
  font-size: 11px;
}

.section-posts ul li .post-content .post-message {
  padding: 0px;
  margin-bottom: 10px;
}

.section-posts ul li .post-content .post-message p {
  padding: 0 5px;
  font-size: 13px;
  line-height: 23px;
  color: #444;
}

.section-posts ul li .post-content .post-side {
  position: absolute;
  right: 15px;
  top: 23px;
  font-size: 19px;
  color: #444
}

.section-posts ul li .post-content .post-side.r5 {
  color: #6b94af
}

.section-posts ul li .post-content .post-side.r1 {
  top: 23px;
  color: #af6b72
}

.section-posts ul li .post-content .post-top {
  margin: 0;
}

.section-posts ul li .post-content .post-top .thumbnail {
  display: inline-block;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  vertical-align: top;
  background-color: #adc4d3;
}

.section-posts ul li .post-content .post-top .profile {
  display: inline-block;
  vertical-align: top;
}

.section-posts ul li .post-content .post-top .profile span {
  display: inline-block;
  margin: 0px 0;
  line-height: 13px;
  font-size: 12px;
  color: #444
}

.section-posts ul li .post-content .post-top .profile span.name {
  font-weight: 700;
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
}

.section-posts ul li .post-content .post-top .btn {
  border: 0;
  background-color: transparent;
  font-size: 12px
}

.section-posts ul li .post-content .post-top .btn.delete {
  display: inline-block;
  color: #DA5136;
}

.section-posts ul li .post-content .post-top .btn.delete:hover {
  color: #bb3b22
}

.section-posts ul li .post-link {
  padding: 10px;
  margin: 20px 0 0 0;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  background-color: #fafafa;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.section-posts ul li .post-link .col {
  display: inline-block;
}

.section-posts ul li .post-link .thumbnail {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 0px solid #eee;
  margin-right: 15px;
  vertical-align: top;
  background-color: transparent;
  border-radius: 100%;
}

.section-posts ul li .post-link .info {
  display: inline-block;
  vertical-align: top;
}

.section-posts ul li .post-link .info span {
  display: block;
  margin: 0;
  font-size: 12px;
}

.section-posts ul li .post-link .info span.name {
  font-weight: 900;
  font-size: 13px;
  margin: 5px 0 7px 0;
}

.section-posts ul li .post-link:hover {
  border-color: #aaa;
}

.section-posts .section-comments {
  margin: 15px 0 0px 0;
  border-top: 0px solid #ddd;
}

.section-posts .section-comments>.btn.comments {
  display: block;
  width: 100%;
  padding: 5px;
  background-color: #eee;
  color: #444;
  border: 0;
  border-bottom: 2px solid #ddd;
  border-top: 0px solid #ddd;
  border-radius: 5px 5px 0 0;
}

.section-posts .section-comments ul {
  padding: 0;
  margin: 10px 0;
}

.section-posts .section-comments ul li.comment {
  position: relative;
  padding: 8px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fafafa;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.section-posts .section-comments ul li.comment:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-right-color: #fafafa;
  border-left: 0;
  border-top: 0;
  margin-top: -17px;
  margin-left: -10px;
}

.section-posts .section-comments .comment-top .thumbnail {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #eee;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  vertical-align: top;
  background-color: #fefefe;
}

.section-posts .section-comments .comment-top .profile {
  display: inline-block;
  vertical-align: top;
}

.section-posts .section-comments .comment-top .profile span {
  display: block;
  margin: 5px 0;
}

.section-posts .section-comments .comment-top .profile span.name {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  margin: 3px 0;
  color: #444;
}

.section-posts .section-comments .comment-top .profile span.date {
  display: inline-block;
  font-size: 10px;
  margin-left: 10px;
  color: #444;
}

.section-posts .section-comments p {
  margin: 5px 0;
  font-size: 12px;
  line-height: 15px;
  color: #444;
}

.section-posts .section-comments .comment-top .btn {
  border: 0;
  background-color: transparent;
  font-size: 12px
}

.section-posts .section-comments .comment-top .btn.delete {
  display: inline-block;
  color: #DA5136;
}

.section-posts .section-comments .comment-top .btn.delete:hover {
  color: #bb3b22
}

.section-comments .comment-form {
  position: relative;
  display: block;
}

.section-comments .comment-form .row {
  display: block;
  margin: 0px 0;
}

.section-comments .comment-form .row input.txt {
  display: block;
  width: 100%;
  padding: 5px 10px;
  margin: 0;
  border: 0;
  border-radius: 5px;
  line-height: 20px;
  font-size: 13px;
  border: 1px solid #fff;
  -webkit-appearance: none;
  color: #444;
}

.section-comments .comment-form .row .btn-submit {
  width: 50px;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  line-height: 17px;
  font-size: 13px;
}

.section-comments .comment-form .row input.txt:focus {
  margin: 0;
  border: 1px solid #eee;
  line-height: 20px;
  font-size: 13px;
}

.post-form {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #cfdce5;
  padding: 10px;
}

.post-form form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.post-form .row {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 5px;
}

.post-form .row label {
  display: flex;
  padding: 10px 5px;
  /* margin: 0 0 5px 0; */
  font-size: 15px;
  font-weight: 700;
  /* line-height:25px; */
  /* background-color: #8cacc1; */
  color: #444;
  /* border-bottom: 1px solid #8cacc1; */
  /* border-radius: 10px; */
  /* border: 0; */
  /* text-align: center; */
}

.post-form .row input,
.post-form .row textarea {
  display: flex;
  flex: 1;
  /* width: 100%; */
  padding: 15px;
  font-size: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
  /* box-sizing: border-box; */
  /* -moz-box-sizing: border-box; */
  /* -webkit-box-sizing: border-box; */
  resize: none;
  color: #000;
  font-family: 'Noto Sans KR';
  -webkit-appearance: none;
  appearance: none;
}

.post-form .row input::placeholder {
  color: #444;
  opacity: 0.7
}

.post-form .row.file {
  padding: 0 10px;
}

.post-form .row.file label {
  width: 70px
}

.post-form .row.file label i {
  margin-right: 10px;
}

.post-form .row.file input {
  display: inline-block;
  width: 200px;
  opacity: 0
}

.post-form .row.file .filename {
  margin-left: 20px;
  color: #444;
  font-size: 12px;
}

.post-form .row input:focus,
.post-form .row textarea:focus,
.post-form .row textarea:active {
  border-color: #8EC28A;
  outline: none !important;
}

.post-form .row>button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* height: 40px; */
  margin: 0;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  background-color: #24b47e;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  /* width: 100%; */
  color: #fff;
}

.post-form .row button:disabled {
  opacity: 0.6;
}

.post-form .attachment-form button {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 17px;
  color: #fff;
  background-color: #8cacc1;
  border: 0;
  border-radius: 5px;
}

.post-form .attachment-form button i {
  margin-right: 10px;
  font-size: 18px;
  color: #fff;
  vertical-align: top;
}

.post-form .attachment-form input[type=file] {
  display: none
}

.post-form .attachment-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  padding: 5px;
}

.post-form .attachment-list li {
  display: flex;
  /* margin: 0 5px; */
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.post-form .attachment-list li.message {
  font-size: 13px;
  margin: 10px auto;
}

.post-form .attachment-list li img {
  width: 90px;
  height: 90px;
  /* border-radius: 3px; */
  opacity: 0.5
}

.post-form .attachment-list li .btn-del {
  display: flex;
  position: absolute;
  top: 50%;
  right: 50%;
  margin: -12px -10px;
  font-size: 20px;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 100%;
}

.post-form .attachment-list li .btn-del i {
  color: #444;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.post-form .ratings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 10px 0;
}

.post-form .ratings li {
  display: flex;
  flex: 0;
  flex-direction: row;
  position: relative;
  /* width: 100px; */
  /* height: 100px; */
}

/* .post-form .ratings li+li {
  margin-left: 40px;
} */

.post-form .ratings input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.post-form .ratings label {
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 19px;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: #eee;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.post-form .ratings input:hover+label,
.post-form .ratings input:checked+label,
.post-form .ratings input:focus+label,
.post-form .ratings input:active+label {
  font-weight: 900;
  color: #24b47e;
  background-color: #fff;
  border: 1px solid #24b47e;
  opacity: 1;
}

.post-form .ratings label span {
  position: absolute;
  left: -20px;
  top: -10px;
  display: block;
  width: 25px;
  margin: 3px 0;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #24b47e;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.post-form .ratings label span.down {
  background-color: #24b47e;
}

.account-wrapper {
  /* position: relative; */
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding: 30px 0 50px 0;
  align-items: center;
  row-gap: 15px;
}

.account-wrapper.centered {
  justify-content: center;
}

.account-wrapper .modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #444;
  border-bottom: 1px solid #333;
  text-align: center;
  opacity: 0.9;
  z-index: 999;
}

.account-wrapper .modal .message {
  color: #fff;
  font-size: 14px;
  line-height: 50px;
}

.account-wrapper .row.msg {
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.account-wrapper .row.msg span.span-msg {
  display: block;
  padding: 20px 0px 10px 0;
  color: #444;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  background-color: #fff;
}

.account-wrapper .row.msg .btn.kakao {
  margin: 15px auto;
  box-shadow: none;
}

.account-wrapper .row.msg .btn.phone {
  margin: 15px auto;
  box-shadow: none;
}

.account-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  row-gap: 15px;
  position: relative;
}

.account-form h2 {
  display: flex;
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  font-weight: 900;
  font-size: 13px;
  background-color: #f1f1f1;
  border-radius: 5px;
  color: #444;
}

.account-form .row {
  display: flex;
  justify-content: center;
  /* margin: 0 auto 10px auto; */
  /* text-align: left; */
}

.account-form .row:last-child {
  margin-top: 20px;
}

.account-form .row.hide {
  display: none
}

.account-form .row.top {
  margin-bottom: 15px;
}

.account-form .row .col {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 5px;
}

.account-form .row .col+.col {
  margin-left: 10px;
}

.account-form .row .col>label {
  display: flex;
  line-height: 24px;
  /* margin: 0 0 3px 0; */
  /* padding: 0 5px; */
  color: #444;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #24b47e;
  /* border-radius: 5px; */
}

.account-form .row .col>.tooltip {
  display: block;
  margin: 10px 5px 15px 5px;
  font-size: 13px;
  color: #777;
}

.account-form .row .col>input:read-only {
  color: #444;
  background-color: #fafafa;
  border: 0;
  border: 1px solid #eee;
}

.account-form .row .col>input {
  display: block;
  width: 100%;
  line-height: 35px;
  padding: 10px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 18px;
  color: #444;
  font-weight: 700;
  background-color: #fff;
  -webkit-appearance: none;
  appearance: none;
}

.account-form .row .btn span {
  display: block;
  font-size: 16px;
}

.account-form .row .btn.kakao span {
  display: inline-block;
  font-size: 16px;
}

.account-form .row .btn.naver span {
  display: inline-flex;
  font-size: 15px;
  line-height: 22px;
}

.account-form .row .btn.verify,
.account-form .row .btn.signin,
.account-form .row .btn-event {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0px;
  background-color: #24b47e;
  color: #fff;
  border-radius: 5px;
  font-weight: 900;
  font-size: 13px;
  border: 2px solid #24b47e;
}

.account-form .row .btn:disabled {
  color: #444;
  opacity: 0.7
}

.account-form .row .message {
  display: block;
  /* margin: 10px auto; */
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: #444;
  font-weight: 500;
}

.account-form .row.top .message {
  margin: 0px auto;
  padding: 0;
  color: #24b47e;
  font-size: 15px;
  font-weight: 700;
}

.account-form .row .btn.kakao {
  background-color: #FFCD00;
  border: 1px solid #FFCD00;
  color: #1E1E1E;
}

.account-form .row #naverIdLogin_loginButton {
  border-radius: 5px;
  text-align: center;
}

.account-wrapper .ul-stars {
  margin: 10px 0;
}

.account-wrapper .ul li {
  position: relative;
  margin: 0;
  font-size: 12px;
  border-bottom: 0px solid #eee;
  border-radius: 0px;
}

.account-wrapper .ul li.li-empty {
  margin: 0;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  background-color: #fefefe;
  text-align: center;
  border-radius: 5px;
}

.account-wrapper .ul li.true {
  color: #4D8802;
  background-color: #f2f8f1;
}

.account-wrapper .ul li.true>.link span {
  font-weight: 700
}

.account-wrapper .ul li:hover {
  color: #4D8802;
  background-color: #f2f8f1;
}

.account-wrapper .ul li>.link {
  display: block;
  height: 40px;
  margin: 0;
  padding: 0px 10px;
  font-size: 16px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
  text-align: left;
  color: #444;
}

.account-wrapper .ul li>.link i {
  display: inline-block;
  margin-left: 10px;
  font-size: 11px;
  vertical-align: top;
  line-height: 40px;
  color: #666
}

.account-wrapper .ul li>.link span {
  line-height: 40px;
}

.account-wrapper .ul li:hover>.link i {
  display: inline-block;
  color: #1A7D0C;
}

.account-wrapper .row .btn-submit {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #24b47e;
  border: 0;
  /* border-bottom:2px solid #40733c; */
  color: #fff;
  border-radius: 10px;
  font-weight: 900;
  font-size: 16px;
}

.account-wrapper .row .btn-submit:disabled {
  background-color: #eee;
  color: #999;
  border: 0;
  border-bottom: 0px solid #ddd;
}

/* .account-wrapper .row .btn-submit:hover {background-color:#8EC28A;} */
/* .account-wrapper .row .btn-submit:disabled:hover {border-color:#aaa;} */

@media only screen and (min-width: 200px) and (max-width: 750px) {
  .loading-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    margin-left: -25px;
    margin-top: -25px;
  }

  .icon-loading {
    border: 10px solid #24b47e;
    border-top: 10px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  footer .footer-modal {
    display: none;
  }

  header h1 {
    left: 5px;
    line-height: 30px;
    font-size: 12px;
    opacity: 0.8;
    border-radius: 10px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  header li .btn.account,
  header li .btn.kakao span {
    font-size: 10px;
  }

  .section-map {
    touch-action: manipulation;
    width: 100vw;
    height: 100vh;
  }

  .map-control {
    top: auto;
    bottom: 70px;
    left: auto;
    right: 10px;
    z-index: 9999;
  }

  .map-control .zoom-control {
    display: none;
  }

  .map-control ul li button.btn span {
    display: none
  }

  .map-control ul li button.btn:hover {
    border-color: #fff;
  }

  .map-control ul li button.btn:hover span {
    display: none
  }

  .section-contents {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100dvw;
    height: 100dvh;
    box-shadow: none;
  }

  .sidebar .sidebar-top {
    padding: 12px 0;
  }

  /* .sidebar .sidebar-menu {
    padding: 5px;
  }

  .sidebar .sidebar-menu .ul-menu li {
    padding: 0;
  }

  .sidebar .sidebar-menu .ul-menu li+li {
    padding: 0 0 0 5px;
  }

  .sidebar .sidebar-menu .ul-menu li .btn {
    padding: 7px;
  }

  .sidebar .sidebar-menu .ul-menu li .btn i {
    display: none;
  } */

  /* .sidebar .sidebar-menu .ul-menu li .btn span {
    font-size: 14px;
    line-height: 15px;
    margin: 0px
  } */

  .sidebar .search-form-option .btn.btn-map {
    display: block
  }

  .sidebar .search-control .ul-tags {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 10px;
  }

  .sidebar .sidebar-footer {
    display: none
  }

  .sidebar .sidebar-footer ul.ul-footer {
    display: none
  }

  .sidebar .sidebar-footer .ul-btns {
    display: block;
    position: relative;
  }

  .sidebar .sidebar-footer .ul-btns li {
    display: block;
    margin-bottom: 5px;
  }

  .sidebar .sidebar-footer .ul-btns li .btn {
    width: auto;
    height: 30px;
    line-height: 18px;
    font-size: 13px;
  }

  .sidebar .sidebar-footer .ul-btns li .btn {
    padding: 5px 10px;
    border-radius: 5px;
  }

  .sidebar .sidebar-footer .ul-btns li .btn span {
    margin-left: 5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }

  .sidebar .sidebar-footer .ul-btns li:first-child {
    display: none
  }

  .sidebar .search-list ul li .btn {
    height: auto;
  }

  .sidebar .search-list ul li .btn .title {
    flex-direction: column;
  }

  /* .sidebar .search-list ul li .btn .title .name {
    font-size: 14px;
  } */

  .sidebar .search-list ul li .btn .title .badge {
    margin: 3px 0;
  }

  .sidebar .search-list ul li .btn .img.no-img,
  .sidebar .search-list ul li .btn .img img {
    height: 80px;
    width: 80px
  }

  .sidebar .search-list ul li .btn span.desc {
    font-size: 15px;
  }

  /* .account-form {
    width: auto
  } */

  .section-contents.false {
    top: 15px;
    height: auto;
    bottom: auto;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .sidebar.false {
    background-color: transparent;
  }

  .sidebar.false .sidebar-footer {
    display: block;
    position: relative;
    top: 0px;
    left: auto;
    right: auto;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
  }

  .sidebar.false .sidebar-footer .ul-btns li {
    display: block;
    text-align: left;
    background-color: transparent;
    margin: 0;
  }

  .sidebar.false .sidebar-footer .ul-btns li+li {
    margin: 5px 0 0 0;
  }

  .sidebar.false .sidebar-footer .ul-btns li .btn {
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
    border-radius: 0 10px 10px 0;
    padding: 0;
    height: 40px;
    width: 40px;
    font-size: 17px;
    background-color: #fff;
  }

  .sidebar.false .sidebar-footer .ul-btns li .btn i {
    color: #6872e5;
    font-size: 20px;
  }

  .sidebar.false .search-control {
    /* text-align: left; */
    margin: 0;
  }

  .sidebar.false .search-control .btn.toggle {
    display: none
  }

  .sidebar.false .search-control .ul-tags {
    padding: 0;
  }

  .sidebar.false .search-control li input:checked+label:hover {
    border: 1px solid #444;
    background-color: #444;
    color: #fff;
    transition: all .2s;
  }

  .sidebar.false .search-control li label:focus,
  .sidebar.false .search-control li label:hover,
  .sidebar.false .search-control li label {
    opacity: 1;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.32);
    border: 0;
    background-color: #24b47e;
    color: #fff;
  }

  .sidebar.false .sidebar-top {
    display: none
  }

  .sidebar.false .sidebar-menu {
    display: none
  }

  .sidebar.false .search-list {
    display: none
  }

  .sidebar.false .search-form {
    display: none
  }

  .sidebar.false .search-form-option {
    display: none
  }

  .sidebar.false .sidebar-ad {
    display: none
  }

  /* 
  .account-wrapper {
    padding: 0 0 100px 0;
  } */

  .account-wrapper .ul-stars li .link i {
    display: inline-block;
    color: #ddd;
  }

  .search-info,
  .search-info.visual {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 13px 40px 13px;
    margin: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    border: 0;
    z-index: 99999;
  }

  .search-info.visual h2 {
    font-size: 19px;
    line-height: 23px;
  }

  .search-info.visual .ul-info {
    padding: 0
  }

  .search-info.visual .ul-info li {
    margin: 0 0 10px 0;
  }

  /* .search-info.visual .ul-info li span,
  .search-info.visual .ul-info li input {
    font-size: 16px;
  } */

  /* .search-info.visual .ul-tabs li .btn span {
    font-size: 16px;
  } */

  .search-info.visual .ul-tabs li .btn .badge {
    margin-left: 3px
  }

  .search-info.visual .search-info-control {
    top: 45px;
    right: 20px;
  }

  .search-info.visual .search-info-control button {
    display: block;
    padding: 0;
  }

  .search-info.visual .div-info {
    margin: 5px;
    border-width: 1px;
  }

  .search-info.visual .div-info span {
    font-size: 13px !important;
  }

  .search-info.visual .div-info2.info-hours {
    width: 100%;
  }

  /* .search-info.visual .div-info2.info-hours li {
    display: flex;
    flex-direction: row;
    align-items: center;
  } */

  .search-info.visual .div-info2.info-prices ul li {
    display: flex;
    justify-content: space-evenly;
  }

  .search-info.visual .div-info2.info-prices ul li div:first-child {
    padding: 0;
  }

  .search-info.visual .div-info2.info-prices ul li div {
    padding: 0;
    font-size: 14px;
  }

  .search-info.visual .div-img.info-img .slick-slider {
    width: 100%
  }

  .search-info.visual .event-form {
    border-width: 1px;
    border-radius: 10px;
  }

  .search-info.visual .event-form .row .col.long {
    display: block;
  }

  .search-info.visual .reserve-form .row .col.agreement {
    padding: 10px;
  }

  .search-info.visual .event-form .row .col>input {
    display: block;
    width: 100%;
  }

  .search-info.visual .event-form .row .col>input.inp-short {
    display: inline-block;
    width: 100px;
  }

  .search-info.visual .event-info .event-info-control {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin-top: 10px;
  }

  .search-info.visual .div-img.info-img {
    width: 100%
  }

  .search-info.visual .div-img.info-img img {
    width: 100%;
    margin: 5px 0;
  }

  .section-reviews ul li .post-content .post-image img {
    max-width: 100%;
    max-height: 400px;
  }

  .section-posts h3 {
    margin: 5px 10px;
  }

  .instructor-list ul {
    flex-direction: column;
  }

  .instructor-list .photo {
    width: 130px;
    height: 130px;
  }

  .btn.star.false:hover::before {
    color: #aaa !important
  }

  .btn.star.true:hover::before {
    color: #FFE810
  }
}